import { FC, useEffect, useState } from 'react'
import { Star } from '../icons/star'
import style from './stars.module.scss'

type StarsReviewProp = {
  rating: any
  onClick?: (val: any) => void
  size?: number
  color?: string
  className?: any
}

export const StarsReview: FC<StarsReviewProp> = (props) => {
  const [, _setRating] = useState([] as any)
  const [, _setEmpty] = useState([] as any)

  const color = [
    '#6ee673',
    '#fa141a',
    '#ff585d',
    '#ff7a00',
    '#82d563',
    '#2ca000',
  ]

  useEffect(() => {
    _setRating(new ArrayBuffer(Number(props.rating)))
    _setEmpty(new ArrayBuffer(5 - Number(props.rating)))
    return () => {}
  }, [props.rating])
  return (
    <div className={style.stars}>
      {new Array(5).fill(0).map((_ele: any, i: number) => (
        <button
          key={i}
          onClick={() => (props.onClick ? props.onClick(i + 1) : '')}
          type="button"
        >
          <Star
            size={props.size}
            type={i < props.rating ? 'FILL' : 'EMPTY'}
            rating={color[props.rating]}
            emptyStar="star"
            fullStar="star"
            starDimension="30px"
            halfStar="star-half"
            color={i < props.rating ? color[props.rating] : '#ddd'}
          />
        </button>
      ))}
    </div>
  )
}
