import { FC } from 'react'

type ChevronBottomProp = {
  color?: string
  classname?: string
}

export const ChevronBottom: FC<ChevronBottomProp | any> = (props) => (
  <svg
    className={`${props.classname ? props.classname : 'w-5 h-5'}`}
    fill="none"
    viewBox="0 0 24 24"
    stroke={props.color ? props.color : '#adaaaa'}
    strokeWidth="2"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
  </svg>
)
