import Link from 'next/link'
import { FC, useState, useLayoutEffect, useRef } from 'react'
import { CheveronLeft } from '../icons/cheveron-left'
import { CheveronRight } from '../icons/cheveron-right'
import { InnerHtml } from '../inner-html/inner-html'

import {
  MultiSelectTag,
  MultiSelectTagType,
} from '../multi-select-tag/multi-select-tag'
import style from './videoplay-scroll-wrapper.module.scss'

export enum VideoScrollType {
  NORMAL,
  SOLO,
  OVERFLOW,
}

export interface VideoScrollWrapperProps {
  /**
   * array of children
   */
  children: any[]
  /**
   * title for the scrollbar  j
   */
  title?: string
  /**
   * this will show filter
   */
  withFilter?: boolean
  showAllLink?: string
  sliderButton?: boolean
  products?: boolean
  colWidth?: string
  type: VideoScrollType
  scrollLength?: number
  smoothBorder?: string
  headerHtml?: any
  styLe?: boolean
}

export const VideoScrollWrapper: FC<any> = ({
  children,
  title,
  withFilter,
  showAllLink,
  sliderButton,
  products,
  colWidth,
  type,
  scrollLength,
  smoothBorder,
  headerHtml,
  styLe,
}) => {
  const typeClass: any = {
    [VideoScrollType.SOLO]: 'scroller--solo',
    [VideoScrollType.NORMAL]: '',
    [VideoScrollType.OVERFLOW]: 'scroller--overflow',
  }

  const wrapperEle: any = useRef(null)

  const scrollL = () => scrollLength || wrapperEle.current.clientWidth

  const scrollRight = () => {
    const distance = wrapperEle.current.scrollLeft + scrollL()
    wrapperEle.current.scrollTo({ left: distance, behavior: 'smooth' })
  }

  const scrollLeft = () => {
    const distance = wrapperEle.current.scrollLeft - scrollL()
    wrapperEle.current.scrollTo({ left: distance, behavior: 'smooth' })
  }

  const horizontalScrollElement = useRef<HTMLDivElement | null | any>()

  const [eleWidth, setEleWidth] = useState(colWidth)
  useLayoutEffect(() => {
    if (!colWidth) {
      let width = 0
      if (window.innerWidth < 360) {
        width = horizontalScrollElement.current.scrollWidth / 1 - 16
        setEleWidth(`${width}px`)
      } else if (window.innerWidth > 360 && window.innerWidth < 575) {
        width = horizontalScrollElement.current.scrollWidth / 1 - 16
        setEleWidth(`${width}px`)
      } else if (window.innerWidth > 576 && window.innerWidth < 767) {
        width = horizontalScrollElement.current.scrollWidth / 2 - 16
        setEleWidth(`${width}px`)
      } else if (window.innerWidth > 767 && window.innerWidth < 991) {
        width = horizontalScrollElement.current.scrollWidth / 3 - 16
        setEleWidth(`${width}px`)
      } else if (window.innerWidth >= 991 && window.innerWidth < 1200) {
        width = horizontalScrollElement.current.scrollWidth / 3 - 16
        setEleWidth(`${width}px`)
      } else if (window.innerWidth >= 1200 && window.innerWidth < 1600) {
        width = horizontalScrollElement.current.scrollWidth / 4 - 16
        setEleWidth(`${width}px`)
      } else if (window.innerWidth >= 1600) {
        width = horizontalScrollElement.current.scrollWidth / 4 - 16
        setEleWidth(`${width}px`)
      }
    }
    return () => {}
  }, [])

  return (
    <div
      ref={horizontalScrollElement}
      style={{
        borderRadius: `${smoothBorder}`,
        background: `${styLe && 'none'}`,
        boxShadow: `${styLe && 'none'}`,
      }}
      className={`${style.scroller} ${style[`${typeClass[type]}`]} ${
        products ? style.products : ''
      }`}
    >
      {type == VideoScrollType.NORMAL ? (
        // scrolled header
        <div className={style.scroller__head}>
          {/* show title if title exists */}
          {title ? (
            <div className={style.title}>
              <InnerHtml data={title} />
            </div>
          ) : (
            ''
          )}
          {!!headerHtml ? headerHtml : ''}

          {/* provide space between items */}
          <div className={style.spacer} />

          {/* show tags if withFilter if option exits */}
          {withFilter ? (
            <MultiSelectTag
              name="price"
              items={['one', 'two', 'three']}
              onChange={(_va) => {}}
              type={MultiSelectTagType.Multiple}
            />
          ) : (
            ''
          )}

          {sliderButton ? (
            // show slider button is sliderButton option is true
            <div className={style.scroller__action}>
              {/* show all if showAllLink is provided */}
              {showAllLink ? (
                <Link href={showAllLink} passHref className={style.show_all}>
                  See all
                </Link>
              ) : (
                ''
              )}

              <button
                className={style.btn_left}
                onClick={scrollLeft}
                aria-label="left"
              >
                <CheveronLeft />
              </button>
              <button
                className={style.btn_right}
                onClick={scrollRight}
                aria-label="right"
              >
                <CheveronRight />
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}

      {type == VideoScrollType.SOLO ? (
        <button
          className={`${styLe ? style.videoscroll : style.solo_btn} ${
            style['solo_btn--left']
          }`}
          onClick={scrollLeft}
        >
          <CheveronLeft />
        </button>
      ) : (
        ''
      )}
      {type == VideoScrollType.SOLO || type == VideoScrollType.OVERFLOW ? (
        <button
          className={`${styLe ? style.videoscroll : style.solo_btn} ${
            style['solo_btn--right']
          }`}
          onClick={scrollRight}
        >
          <CheveronRight />
        </button>
      ) : (
        ''
      )}

      <div
        className={style.scroller__wrapper}
        style={{
          gridTemplateColumns: `repeat(${children?.length}, ${
            eleWidth || '260px'
          })`,
        }}
        ref={wrapperEle}
      >
        {children}
      </div>
    </div>
  )
}
export default VideoScrollWrapper
