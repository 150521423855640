import Head from 'next/head'
import { useRouter } from 'next/router'
import { Config } from '~/config/config'
/**
 * App Head Type used to show a seo based content in header.
 * we have used slug for seo
 * to show a product or category link in social medias.
 */
import { useMemo } from 'react'
export interface AppHeadType {
  title: string
  slug?: string
  description: string
  image?: string
  keywords?: string
  categoryTags?: string
  twitter?: twitter
  facebook?: string
  canonical?: string
  children?: any
  modallist?: any
  metaData?: {
    content: string
    name: string
  }[]
}
interface AdditionalData {
  price: any
  rating: any
  ratingCount: any
  sku: any
  brand: any
  status: any
  slug: any
}

interface twitter {
  handle?: string
  site?: string
}

export const AppHead = (props: AppHeadType) => {
  const splitTags = props.categoryTags?.split(':=')
  const value = splitTags == undefined ? '' : splitTags[0]

  const router = useRouter()
  const getHeader = (name: string) =>
    props.metaData?.find((e) => e.name == name)
  const title = `${props.title != undefined ? props.title : ''}`
  const description =
    props?.description != ''
      ? `${props.description}`
      : !!getHeader('description')
      ? getHeader('description').content
      : 'Best Online Shopping  for Mobiles, Laptops, Home Appliances & more in India'
  const keywords = `${
    props?.keywords != ''
      ? `${props.keywords}`
      : !!getHeader('keywords')
      ? getHeader('keywords')
      : ''
  }, Poorvika, Electronics, mobiles`
  const noIndexHeader = useMemo(
    () =>
      Config.ENVIRONMENT == 'production' ||
      Config.ENVIRONMENT == 'uat' ||
      Config.ENVIRONMENT == 'pimlive'
        ? value === 'tags' || props?.modallist?.length == 1
          ? [
              <meta key={'robots'} name="robots" content="noindex" />,
              <meta key={'theme'} name="theme-color" content="#f46b27" />,
              <meta key={'googlebot'} name="googlebot" content="noindex" />,
            ]
          : []
        : [
            <meta key={'robots'} name="robots" content="noindex" />,
            <meta key={'theme'} name="theme-color" content="#f46b27" />,
            <meta key={'googlebot'} name="googlebot" content="noindex" />,
          ],
    []
  )

  const metaData = useMemo(
    () =>
      !!props.metaData
        ? [
            props.metaData.map((e, i) => (
              <meta key={i} name={e.name} content={e.content} />
            )),
          ]
        : [
            <meta key={'keywords'} name="keywords" content={keywords} />,
            <meta
              key={'description'}
              name="description"
              content={description}
            />,
          ],
    []
  )
  return (
    <>
      <Head>
        <title>{title}</title>
        {props.canonical && (
          <link rel="canonical" href={props.canonical} media="all" />
        )}
        {noIndexHeader}
        <meta name="title" content={title} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        {metaData}
        <meta property="og:url" content={router.pathname} />
        <meta property="og:type" content="e-commercing" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={
            !!props.image
              ? props.image
              : 'https://cdn.example.com/uploads/images/webpage_300x200.png'
          }
        />
        <link rel="manifest" as="json" href="/manifest.json" media="all" />
        {/* <link
          href="//fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        ></link> */}
        {props.children}
      </Head>
    </>
  )
}
